<template>
  <VideoPlayer />
</template>

<script>
import VideoPlayer from './components/VideoPlayer.vue';

export default {
  name: 'App',
  components: {
    VideoPlayer,
  },
};
</script>

<style>
/* Global styles can go here */
</style>
