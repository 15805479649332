<template>
  <div class="flex flex-col min-h-screen bg-gray-900 text-white">
    <div class="container mx-auto p-4">
      <h1 class="md:text-6xl text-4xl font-black mb-3 text-center">Tempodrome Live</h1>
      <h2 class="md:text-base text-xs font-light mb-6 italic text-center">
        Multi-Angle Video Streaming Proof of Concept
      </h2>
    </div>

    <div v-if="!isSafariOrIphone" class="flex flex-1 flex-col justify-center items-center container mx-auto p-4">
      <div class="grid grid-cols-4 md:grid-cols-8 gap-0 mb-6">
        <button
            v-for="(person, index) in people"
            :key="person"
            @click="switchAngle(index)"
            :class="[
            'w-full transition duration-250',
            'px-2 py-2 md:px-3 md:py-3',
            currentAngleIndex === index
              ? 'bg-transparent text-white shadow-lg border-2 transform scale-105 hover:text-black hover:bg-gray-100'
              : 'bg-transparent text-white hover:text-black hover:bg-gray-100',
            computeRoundingClasses(index)
          ]"
        >
          {{ person }}
        </button>
      </div>

      <video
          ref="videoPlayer"
          class="w-full rounded-lg border border-gray-700 shadow-lg"
          controls
          preload="auto"
          playsinline
      ></video>

      <div class="h-40 md:h-16"></div> <!-- Adjust the height values as needed -->
    </div>

    <div v-else class="flex flex-1 flex-col justify-center items-center container mx-auto p-4">
      <p class="text-red-500 text-center text-lg md:text-xl font-semibold">
        This multi-angle video streaming demo is not supported on Safari or iOS devices yet. Please use a different browser for the best experience.
      </p>
    </div>

    <!-- Footer -->
    <footer class="p-6 text-center text-gray-500 text-xs md:text-ms">
      <a
          href="https://github.com/RaphaelMaschinsen"
          class="text-blue-500 hover:underline"
      >
        https://github.com/RaphaelMaschinsen
      </a>
      — Something not working right? Let me know! I'm to blame 😇
    </footer>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import dashjs from 'dashjs';

export default {
  name: 'VideoPlayer',
  setup() {
    const videoPlayer = ref(null);
    const dashPlayer = ref(null);
    const currentAngleIndex = ref(0);
    const people = ['JANOS', 'JULIAN', 'LENA', 'MATTIA', 'MAX', 'SILVAN', 'TILL', 'ZINKI'];
    const isSafariOrIphone = ref(false);

    const switchAngle = (index) => {
      currentAngleIndex.value = index;
      updateAngle(people[index]);
    };

    const updateAngle = (personName) => {
      if (dashPlayer.value) {
        const videoTracks = dashPlayer.value.getTracksFor('video');
        const targetVideoTrack = videoTracks.find((track) =>
            (track.supplementalPropertiesAsArray || []).some(
                (property) =>
                    property.schemeIdUri === 'urn:aurilia:dash:camera-angle' &&
                    property.value === personName
            )
        );

        if (targetVideoTrack) {
          dashPlayer.value.setCurrentTrack(targetVideoTrack);
        } else {
          console.warn(`Video track with angle '${personName}' not found`);
        }

        const audioTracks = dashPlayer.value.getTracksFor('audio');
        const targetAudioTrack = audioTracks.find((track) =>
            (track.supplementalPropertiesAsArray || []).some(
                (property) =>
                    property.schemeIdUri === 'urn:aurilia:dash:camera-angle' &&
                    property.value === personName
            )
        );

        if (targetAudioTrack) {
          dashPlayer.value.setCurrentTrack(targetAudioTrack);
        } else {
          console.warn(`Audio track with angle '${personName}' not found`);
        }
      }
    };

    const computeRoundingClasses = (index) => {
      let classes = '';
      // Mobile (default)
      if (index === 0) {
        classes += 'rounded-tl-lg ';
      }
      if (index === 3) {
        classes += 'rounded-tr-lg ';
      }
      if (index === 4) {
        classes += 'rounded-bl-lg ';
      }
      if (index === 7) {
        classes += 'rounded-br-lg ';
      }

      if (index === 3) {
        classes += 'md:rounded-tr-none ';
      }
      if (index === 4) {
        classes += 'md:rounded-bl-none ';
      }

      // Medium screens and up
      if (index === 0) {
        classes += 'md:rounded-l-lg ';
      }
      if (index === 7) {
        classes += 'md:rounded-r-lg ';
      }

      return classes.trim();
    };

    onMounted(() => {
      // Detect Safari or iOS devices
      const userAgent = navigator.userAgent;
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

      isSafariOrIphone.value = isSafari || isIOS;

      if (!isSafariOrIphone.value) {
        dashPlayer.value = dashjs.MediaPlayer().create();
        dashPlayer.value.updateSettings({
          streaming: {
            trackSwitchMode: {
              video: 'alwaysReplace',
              audio: 'alwaysReplace',
            },
            buffer: {
              bufferPruningInterval: 5,
              bufferTimeAtTopQuality: 20,
              bufferTimeAtTopQualityLongForm: 20,
              stableBufferTime: 6,
              bufferToKeep: 5,
              fastSwitchEnabled: true,
              flushBufferAtTrackSwitch: false,
              resetSourceBuffersForTrackSwitch: false,
            },
            abr: {
              initialBitrate: {
                video: 300,
                audio: 64,
              },
              initialRepresentationRatio: {
                video: 0.1,
              },
            },
          },
        });

        dashPlayer.value.setAutoPlay(true);

        if (videoPlayer.value) {
          videoPlayer.value.pause();
        }

        const masterMPD = 'https://cdn.maschinsen.ch/dash_output/output.mpd';
        dashPlayer.value.initialize(videoPlayer.value, masterMPD, true);

        dashPlayer.value.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, () => {
          updateAngle(people[currentAngleIndex.value]);
        });
      }
    });

    onBeforeUnmount(() => {
      if (dashPlayer.value) {
        dashPlayer.value.reset();
      }
    });

    return {
      videoPlayer,
      people,
      currentAngleIndex,
      switchAngle,
      computeRoundingClasses,
      isSafariOrIphone,
    };
  },
};
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>
